/* ------ Change password css start------ */

.changepw {
  padding: 20px 0 0 0;
  width: 50%;
  margin: 0 auto;

  .ant-input-affix-wrapper {
    border-width: 0px 0px 1px 0px !important;
  }
}

.changepw input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  font-family: 'Poppins', sans-serif;
}

.changepw input::-webkit-input-placeholder {
  color: #a09f9f;
  font-weight: 500;
  font-size: 13px;
}

.changepw .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: none;
  box-shadow: none;
}

.changepw .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 37px;
  padding-top: 0px;
  padding-bottom: 0;
}

/* ------ Change password css end------ */

/*------------------ Responsive------------------ */

@media only screen and (min-width: 360px) and (max-width: 973px) {
  .changepw {
    padding: 20px 12px 0 12px;
    width: 100%;
  }
}
