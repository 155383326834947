.ant-card.registervard {
  border-radius: 8px;
  box-shadow: 4px 3px 20px rgba(0, 0, 0, 0.11);
  height: 483px;
  width: 85%;
  margin: 0 auto;
  /* min-height: 55em;
  max-height: 55em; */
}

.registervard .login-form-button {
  margin-top: 25px;
}

.registerpg {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.registerpage .bgColor {
  /* background: url(http://localhost:3000/static/media/Sign_in_bg_image.95c7a9d2.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 33%;
}

.mobileicon {
  width: 11px;
  margin-left: -5px;
}

.registerpage .paddingbt {
  margin-bottom: 16px;
}

/* .signintext{
    bottom:12px;
} */
.signintext {
  bottom: 14px;
}

.ant-notification.ant-notification-bottomRight {
  right: 0px !important;
  top: auto !important;
  bottom: 108px !important;
  left: 0 !important;
  margin: 0 auto !important;
}

a.ant-notification-notice-close {
  display: none;
}

.infotext {
  margin-bottom: 2px;
  font-size: 9px;
  letter-spacing: 0.5px;
  color: #8bc0ea;
  font-weight: lighter;
  font-family: 'Poppins', sans-serif;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-popover-inner-content {
  padding: 10px 12px;
}

.ant-popover-placement-bottom {
  padding-top: 0;
  margin-left: 31px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  display: none;
}

.paddingbt .ant-select-selection {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
  position: relative;
}

.paddingbt .ant-select-selection__placeholder {
  color: #a99fae;
  font-weight: 500 !important;
  font-size: 14px;
}

.paddingbt .ant-select-selection__rendered {
  margin-left: 37px !important;
}

/* .paddingbt .ant-select-selection::before{
    content: '';
    position: absolute;
    background-image: url('../../images/City.png');
    left: 0;
    top: 0;
    background-size: contain;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
} */
/* .licenceicn{
    width: 22px !important;
    height: 15px;
} */
.paddingbt .ant-select-selection:focus {
  box-shadow: none;
}

.paddingbt .ant-select-selectio:hover,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-bottom: 1px solid #40a9ff !important;
  box-shadow: none !important;
}

/* @media only screen and (min-width:1025px){
    .signintext {
        bottom: 0;
    }
} */
