.slotManagementPage {
  background-color: #ffffff;
  height: 100%;

  .detailsBox {
    margin: 10px 20px;

    .detailsLabel {
      width: 100%;
      padding: 5px 0px;

      .detailsData {
        font-weight: bold;
        padding-left: 5px;
      }
    }

    .generalSlotTimings {
      padding: 20px;
      background-color: #f5f5f5;

      .ant-tabs-card .ant-tabs-content {
        margin-top: -16px;

        .ant-tabs-tabpane {
          padding: 16px;
          background: #fff;
        }
      }

      .editSlot,
      .addSlot {
        float: right;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
}

.ant-modal {
  .addModalLabels,
  .addModalInput {
    width: 180px;
  }

  .timescale {
    font-size: 12px;
    position: relative;
    top: 16px;
    left: 5px;
  }
}
