/* -----Forgot password css start------ */

.usermessage {
  margin-top: 20px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  color: #949292;
  font-weight: 500;
  text-align: center;
  padding: 0 4px;
}

.forgotpw .ant-card-body {
  padding: 11px;
  height: 100%;
}

.forgotpw-form-button {
  margin-top: 60px;
  width: 35%;
  border-radius: 32px;
  height: 42px;
  font-size: 15px;
  letter-spacing: 0.5px;
  /* text-transform: capitalize; */
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6379e6);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.12);
}

.forgotpw {
  margin: 42px 0 22px 0;
}

/* -----forgot password css end------ */

/*------------------ Responsive------------------ */

@media only screen and (min-width: 360px) and (max-width: 973px) {
  .forgotpw-form-button {
    width: 100%;
    /* margin-top: 37px; */
  }
}
