i.fas.fa-ellipsis-h.arrow_back.ellipsis {
  font-size: 16px;
  float: right;
  margin-top: 7px;
  margin-right: 17px;
}

.mycliniccard .ant-card-body {
  padding: 6px 7px 7px 7px;
}

.afterline:after {
  content: '';
  height: 45px;
  width: 1px;
  background-color: #c1c1c12e;
  position: absolute;
  right: -8px;
  top: 0px;
  bottom: 0;
  margin: auto;
}

.hsptlname {
  margin-bottom: 5px !important;
}

.fordesktop1 {
  display: block;
}

@media only screen and (min-width: 360px) and (max-width: 973px) {
  .fordesktop1 {
    display: none;
  }
}
