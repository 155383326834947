/* -----Edit profile css start----- */
.feildrow {
  display: flex;
}

.usernamediv {
  width: 50%;
  padding: 10px;
}

.conferdr.editprofile .ant-select-selection--single {
  width: 451px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
}

.profilePic {
  padding-left: 10px;
}

.profilePicLabel {
  padding-left: 10px;
  width: auto;
  white-space: nowrap;
}

.profilePicLabel:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.profilePicLabel:after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.avatar-uploader > .ant-upload {
  width: 188px;
  height: 38px;
  margin-top: -8px;
}

.editprofile-save-button {
  width: 15%;
  border-radius: 32px;
  height: 42px;
  font-size: 15px;
  margin-bottom: 3px;
  float: right;
  letter-spacing: 0.5px;
  /* text-transform: capitalize; */
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6379e6);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.12);
}

.conferdr.editprofile .loginFormNew {
  height: 100%;
}

/* -----Edit profile css end----- */

/*------------------ Responsive------------------ */

@media only screen and (min-width: 360px) and (max-width: 973px) {
  p.heading {
    width: 100%;
  }

  .conferdr.editprofile .ant-select-selection--single {
    width: 275px;
  }

  .conferdr.editprofile .ant-select.ant-select-enabled {
    width: 275px !important;
  }

  .usernamediv {
    width: 100%;
  }

  .feildrow {
    display: block;
  }

  .editprofile-save-button {
    width: 31%;
    height: 38px;
  }
}
