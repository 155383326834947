/* -----Reset password css start------ */
.resetpw {
  padding: 32px 13px 0 13px;
}

.resetpw-form-button {
  margin-top: 83px;
  width: 35%;
  border-radius: 32px;
  height: 42px;
  font-size: 15px;
  letter-spacing: 0.5px;
  /* text-transform: capitalize; */
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6379e6);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.12);
}

/* -----Reset password css end------ */

/*------------------ Responsive------------------ */
@media only screen and (min-width: 360px) and (max-width: 973px) {
  .resetpw-form-button {
    width: 100%;
  }
}
