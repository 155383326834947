/*------------------------------ Sidenavbar Part start---------------------------------- */
/* .sidenavbarpart{
    display: none;
} */
.layoutsec {
  height: 100%;
}

.formobileview {
  display: none;
}

.aligncntr {
  text-align: center;
}

.fordesktop.ant-layout-sider {
  flex: 0 0 270px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  width: 270px !important;
  background-color: transparent;
  position: unset;
}

.fordesktop {
  display: block;
  height: 100%;
}

.spacting {
  margin-top: 25px;
}

p.profilename.fordesktop {
  color: #ffffff;
}

.sidenavbarpart {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
}

.sidenavbarpart ul {
  height: 100%;
}

.navbartoppart {
  text-align: center;
  /*margin: 22px 0;*/
}

.navbartoppart h1 {
  margin: 0;
  padding: 12px 0;
}

.colorchng {
  color: #6679cf;
}

.navbartoppart span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  height: 74px;
  width: 74px;
  line-height: 79px;
  font-size: 53px;
  border: 1px solid #2069ba;
}

.navbartoppart sup.ant-scroll-number.ant-badge-dot {
  position: absolute;
  top: 60px;
  height: 15px;
  right: 6px;
  width: 15px;
  border: 1px solid white;
  background-color: #2069ba;
}

i.anticon.anticon-edit.editicon {
  margin-right: 6px;
  vertical-align: middle;
  color: #8a8a8a;
  font-size: 14px;
  line-height: 23px;
  /* margin-top: 5px; */
}

p.profiletext {
  font-family: 'Poppins', sans-serif;
  margin-top: 4px;
  font-size: 13px;
  color: #ababab;
  /* margin-bottom: 0; */
}

hr.light {
  width: 215px;
  background: #fbfbfc;
  border: 0;
  /*height: 2px;*/
  /* height: -16px; */
  box-shadow: none;
  outline: none;
}

.backarrow {
  font-size: 20px;
  color: #bec6d6;
  position: absolute;
  top: 30px;
  left: 17px;
}

hr.dark {
  width: 215px;
  background: #e2e1e1;
  border: 0;
  height: 1px;
  /* height: -16px; */
  box-shadow: none;
  outline: none;
}

.menuitem {
  font-family: 'Poppins', sans-serif;
  color: #111111;
}

.ant-menu-item.menuitem > a {
  display: block;
  color: #111111;
}

.ant-menu-item-selected.menuitem > a,
.ant-menu-item-selected.menuitem > a:hover {
  color: #5c75ea !important;
}

.menuitem span {
  vertical-align: middle;
}

.menuitem1 img {
  height: 20px;
  width: 18px;
  margin-right: 25px;
}

.menuitem2 img {
  height: 18px;
  width: 16px;
  margin-right: 25px;
}

.menuitem3 img {
  height: 19px;
  width: 19px;
  margin-right: 25px;
}

.menuitem4 img {
  height: 18px;
  width: 18px;
  margin-right: 25px;
}

.menuitem5 img {
  height: 17px;
  width: 17px;
  margin-right: 25px;
}

.menuitem6 img {
  height: 22px;
  width: 15px;
  margin-right: 25px;
}

.menuitem7 img {
  height: 16px;
  width: 16px;
  margin-right: 25px;
}

.menuitem8 img {
  height: 18px;
  width: 18px;
  margin-right: 25px;
}

/* .menuitem8 {
    position: absolute !important;
    bottom: 0;
    z-index: 99;
} */
/* .menuitem8::before{
    content: '';
    width: 215px;
    border-top: 1px solid #e2e2e2;
    position: absolute;
} */
.dashboardmenulist {
  width: 100%;
  position: relative;
}

p.accountsettingtext {
  margin: 35px 24px 17px;
  font-size: 14px;
  font-weight: 600;
  color: #636465;
}

li.ant-menu-item.menuitem {
  height: 35px;
}

.privacytermssection {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;

  padding: 0 12px;
  font-size: 13px;
  position: absolute;
  bottom: 9px;
  left: 0;
  right: 0;
  color: #b1b1b1;
}

.privacytermssection a {
  color: unset;
}

.privacytermssection a:first-child {
  color: unset;
  border-right: 1px solid #e6e6e6;
  padding-right: 7px;
  margin-right: 7px;
}

p.termstextinmenu {
  margin: 7px 0;
}

.todaymenu sup.ant-scroll-number.ant-badge-count {
  left: 5px;
}

.sidenavbarpart .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
  color: #5c75ea;
}

.ant-menu-item.menuitem:hover {
  background: transparent;
  color: #5c75ea;
}

.sidenavbarpart .ant-menu-inline .ant-menu-selected::after,
.sidenavbarpart .ant-menu-inline .ant-menu-item-selected::after {
  display: none;
}

/*------------------------------ Sidenavbar Part end----------------------------------- */
/*--------------------------------- Tab Content Part start---------------------------------- */
.topbarpart {
  /* background-image: url('../../images/Dashboard-_image.png');
  background-repeat: no-repeat;
  background-size: cover; */
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6779ca);
  width: 100%;
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 50% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0; */
}

.dashboardtab .ant-tabs-content {
  height: 100%;
}

.arrow_back {
  font-size: 20px;
  color: white;
}

i.far.fa-bell {
  font-size: 20px;
  margin-right: 20px;
  margin-top: 1px;
}

i.anticon.anticon-mail.mailicon {
  font-size: 20px;
}

span.rightprt {
  position: absolute;
  right: 21px;
  top: 12px;
  color: white;
}

span.leftprt {
  position: absolute;
  top: 12px;
  left: 8px;
}

img.mailicon {
  width: 17px;
}

/* .firstcard_img {
    margin: 3px 0 3px 0;
} */

.middletoppart {
  text-align: center;
  margin-top: 68px;
}

.toppart {
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6379e6);
  height: 185px;
  width: 385px;
  border-radius: 50%;
  position: absolute;
  top: -70px;
  left: -12px;
  right: 0;
  margin: 0 auto;
}

.topbarpart span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  height: 74px;
  width: 74px;
  line-height: 79px;
  font-size: 53px;
  border: 1px solid #2069ba;
  margin-top: 28px;
  margin-bottom: 5px;
  color: #cccccc;
  background: white;
}

.middletoppart sup.ant-scroll-number.ant-badge-dot {
  position: absolute;
  top: 86px;
  height: 18px;
  right: 6px;
  width: 18px;
  border: 2px solid white;
  background-color: #2069ba;
}

p.profilename {
  text-align: center;
  color: #87a3f6;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  margin-top: 16px;
  letter-spacing: 0.5px;
}

.middlepart {
  /* position: absolute; */
  z-index: 9999999;
  bottom: 30%;
  left: 22%;
}

.topfix {
  /* position: fixed; */
  width: 100%;
  z-index: 9999999;
  background: #fbfcff;
}

img.clinicimg {
  height: 100%;
  width: 100%;
}

.middlepart .ant-card-body {
  padding: 4px;
}

.firstcard .cardtitle {
  margin-top: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.cardtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: rgba(103, 112, 216, 0.7411764705882353);
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 6px;
  margin-top: 3px;
}

p.cardsubtitle {
  color: #d2d1d1;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
}

.fordesktop p.cardsubtitle {
  color: #a6a6a7;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
}

.middlepart .firstcard.ant-card-bordered {
  border: none;
  box-shadow: 0 2px 20px #00000014;
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6779ca);
  border-radius: 4px;
  cursor: pointer;
}

.middlepart .ant-card-bordered {
  margin-bottom: 11px;
  border: none;
  box-shadow: 0 2px 20px #00000014;
  /* background: linear-gradient(to left, #7356aa,#6a64c9, #6278e3,#6379e6); */
  border-radius: 4px;
  cursor: pointer;
}

/* .notificationbadge sup.ant-scroll-number.ant-badge-dot {
    background: linear-gradient(to left, #7356aa,#6a64c9, #6278e3,#6379e6);
    height: 12px;
    width: 12px;
    color: white;
} */
.middlepart .notificationbadge sup.ant-scroll-number.ant-badge-count {
  /* background: -webkit-linear-gradient(right, #7356aa,#6a64c9, #6278e3,#6379e6); */
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6379e6);
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  width: 15px;
  color: white;
  /* right: 0; */
  top: 4%;
  line-height: 13px;
  padding-left: 2px;
  right: 1%;
}

.todaybadge .ant-badge-count {
  background: linear-gradient(to left, #7356aa, #6a64c9, #6278e3, #6379e6);
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  width: 12px;
  color: white;
  /* right: 0; */
  top: 4%;
  line-height: 15px;
  right: 1%;
}

.todaybadge sup.ant-scroll-number.ant-badge-count {
  height: 12px;
  line-height: 12px;
  min-width: 0px;
  padding: 0 1px 0 0;
  font-size: 7px;
  box-shadow: none;
  border-radius: 50%;
  right: 5%;
  top: 19%;
}

.notificationbadge sup.ant-scroll-number.ant-badge-count {
  height: 9px;
  line-height: 11px;
  min-width: 9px;
  padding: 0 1px 0 0;
  font-size: 7px;
  box-shadow: none;
  border-radius: 50%;
  right: 61%;
  top: 41%;
}

/* -----------------------------Tab content Part end------------------------------------- */
/*---------------------------------- Footer css start----------------------------------- */
.dashboardtab .ant-tabs-bottom-bar {
  margin-top: 0;
  border: none;
  position: fixed;
  top: 0;
  width: 89%;
  height: auto;
  z-index: 999;
  background: white;
  box-shadow: 0 -5px 20px #a2b4f8a6;
}

.bottomnavbar {
  height: 100%;
  z-index: 0;
  position: relative;
}

.dashboardtab {
  height: 100%;
  position: relative;
}

.dashboardtab .ant-tabs-tab {
  margin-right: 6px;
  text-align: center;
  padding: 14px 14px 0px 14px;
}

.dashboardtab span.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  display: none;
}

.dashboardtab span.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
  display: none;
}

.dashboardtab .ant-tabs-nav-scroll {
  /* padding-left: 5px; */
  text-align: left;
  padding-right: 5px;
}

.dashboardtab .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding-left: 0;
  padding-right: 0;
}

.dashboardtab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}

/* tab design */
.ant-tabs-tab .tabsec .tabtext {
  font-size: 10px;
  margin-top: 7px;
  font-family: 'Poppins', sans-serif;
  color: #a4a3a3;
}

.dashboardtab .ant-tabs-tab .tabicon {
  font-size: 23px !important;
  color: #868686;
}

.ant-tabs-tab.ant-tabs-tab-active .tabsec .tabtext {
  color: #5c75ea;
}

.dashboardtab .ant-tabs-tab-active .tabicon {
  font-size: 23px !important;
  color: #5c75ea;
}

.menudiv {
  position: fixed;
  bottom: -6px;
  left: 37%;
  z-index: 9999;
}

img.menubar {
  height: 17px;
}

.tabsec {
  text-align: center;
}

i.fas.fa-bars {
  font-size: 23px !important;
  color: #868686;
}

p.tabtext {
  font-size: 10px;
  margin-top: 7px;
  font-family: 'Poppins', sans-serif;
  color: #a4a3a3;
}

img.tabicon1 {
  height: 23px;
  width: 19px;
}

img.tabicon2 {
  height: 22px;
  width: 18px;
}

img.tabicon3 {
  height: 22px;
  width: 22px;
}

img.tabicon4 {
  height: 21px;
  width: 16px;
}

.dashboardtab .ant-tabs-tab-active.ant-tabs-tab {
  background-color: #a2b4f826;
}

.dashboardtab .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: -5px;
}

.dashboardtab .ant-tabs-nav.ant-tabs-nav-animated {
  /* display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; */

  margin-left: 0;
  /* margin-right: 12px; */
}

.tabsectionPart .ant-tabs-nav.ant-tabs-nav-animated {
  /* display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; */

  margin-left: 0px;
  margin-right: 12px;
}

li.ant-menu-item.menuitem.menuitem8 {
  line-clamp: 28px;
}

/* .dashboardtab .ant-tabs-nav.ant-tabs-nav-animated div:first-child {
    margin-left: 57px;
} */
/*----------------------------------------- Footer css end--------------------------- */

/*------------------ Responsive------------------ */
@media only screen and (min-width: 1025px) {
  .hidden-lg {
    display: none;
  }

  .topbarpart.fordesktopversion .middletoppart {
    margin-top: 0;
  }

  .conferdr .hidden-xs {
    height: 100%;
  }

  .fordesktopversion.sectionnamePart {
    margin-top: 0;
  }

  .middlepart .ant-card-body {
    padding: 12px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 973px) {
  .sidenavbarpart {
    overflow-y: scroll !important;
  }

  .dashboardtab .ant-tabs-nav.ant-tabs-nav-animated {
    display: unset;
    margin-left: 0;
  }

  .topbarpart {
    background: none;
    width: unset;
    /* height: 123px */
    height: 105px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: url('../../images/Dashboard-_image.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .menudiv {
    position: fixed;
    bottom: -6px;
    left: 5%;
    z-index: 9999;
  }

  .topfix {
    position: fixed;
    width: 100%;
    z-index: 9999999;
    background-color: white;
  }

  .formobileview {
    display: block;
  }

  .fordesktop {
    display: none;
  }

  .dashboardtab .ant-tabs-bottom-bar {
    border: none;
    position: fixed;
    bottom: 0 !important;
    width: 100%;
    height: 62px;
    z-index: 999;
    background: white;
    box-shadow: 0 -5px 20px #a2b4f8a6;
    margin-top: 16px;
    top: unset;
  }

  .middletoppart {
    text-align: center;
    margin-top: 0;
  }

  span.rightprt {
    position: absolute;
    right: 5%;
    top: 3%;
    color: white;
  }

  span.leftprt {
    position: absolute;
    top: 4%;
    left: 3%;
  }

  .dashboardtab .ant-tabs-nav.ant-tabs-nav-animated {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-left: 82px;
  }

  .tabsectionPart .ant-tabs-nav.ant-tabs-nav-animated {
    margin-left: 0;
    margin-right: 0;
  }

  .navbartoppart {
    text-align: center;
    margin: 60px 0 22px 0;
  }

  .middlepart {
    /* position: fixed; */
    z-index: 0;
    /* margin-top: 28px; */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-flow: column;
    flex-flow: column;
    /* height: 920px; */
    /* height: 1015px; */
    overflow: scroll;
    /* padding-bottom: 341px; */
    /* padding-top: 200px; */
    width: 100%;
    bottom: unset;
    left: unset;
    background: #ffffff;
  }

  .middlepart .tabcards {
    margin-top: 160px;
  }
}

/* iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .topbarpart {
    height: 125px;
  }

  .middlepart .tabcards {
    margin-top: 175px;
  }

  .tabsectionPart .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
    width: 86% !important;
  }
}

/* iPhone 6 7 8 */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .topbarpart {
    height: 125px;
  }

  .middlepart .tabcards {
    margin-top: 170px;
  }

  .tabsectionPart .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
    width: 86%;
  }
}

/* iPhone 6 7 8 Plus */
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .topbarpart {
    height: 125px;
  }

  .middlepart .tabcards {
    margin-top: 175px;
  }

  .tabsectionPart .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
    width: 79% !important;
  }
}

/*
    @media screen
    and (device-width: 360px)
    and (device-height: 640px)
    and (-webkit-device-pixel-ratio: 4)
    and (orientation: portrait) {
        .topbarpart{
      height: 125px;
        }
        .middlepart .tabcards{
      margin-top: 175px;
        }
  } */
