.approvcliniccontent {
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
  height: 520px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.aprovcliniccrd {
  width: 100%;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 0 12px 0 #00000009;
  border-radius: 2px;
}

.userimg {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userimg img {
  width: 50%;
}

.cardbutn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 12px;
}

.cardbutn img {
  width: 20px;
  height: 20px;
}

.crdtxt {
  padding-top: 7px;
}

.crdtxt h3 {
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow-y: hidden;
  white-space: nowrap;
}

.crdtxt p {
  font-size: 13px;
  margin: 0;
  color: #989797;
  letter-spacing: 0.5px;
}

.crosrghtbtn {
  border: none;
  box-shadow: none;
  padding: 0 5px;
}

p.norequestsclinic {
  margin: 0;
  font-size: 17px;
  font-weight: 200;
  color: #b1b1b1;
  font-family: 'Roboto', sans-serif;
  display: flex;
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .cardbutn img {
    width: 25px;
    height: 25px;
  }
}
